
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { number } from "yup/lib/locale";
import { todayDate } from "@/core/data/events";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { form } from "@/core/data/genericData";
import { first } from "lodash";

export default defineComponent({
  props: {
    id: { required: true },
  },

  name: "apps-journal-edit",
  directives: { mask },
  components: {},
  setup(props) {
    const store = useStore();

    const jn_type_data = ref([]);
    const account_type_data = ref([]);
    const account_group_data = ref([]);
    const ac_m_data = ref([]);
    // const journalNumber = ref([]);
    const references = ref([
      { reference_name: "test", reference_id: 1 },
      { reference_name: "new", reference_id: 2 },
      { reference_name: "last", reference_id: 3 },
    ]);

    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const formData = ref({
      tableData: ref<Array<WIjournalData>>([]),
      debit_am_tot: ref<string>("0.00"),
      credit_amtot: ref<string>("0.00"),
      journal_date: moment().format("YYYY-MM-DD"),
      journal_date_type: ref<string | number>(""),
      // reference_type: ref<string>(""),
      journalNumber: ref<string>(""),
      account_type: ref<string>(""),
      account_group: ref<string>(""),
      narration: ref<string>(""),
    });

    getJournalTypes();
    // getAccountTypes();
    interface WIjournalData {
      sr_no: string;
      ledger_id: string;
      ac_name: string;
      debit_am: string;
      credit_am: string;
      delete_row: number;
    }
    // var tableData = ref<Array<WIjournalData>>([]);

    // formData.value["tableData"].push({
    //   ac_name: "",
    //   debit_am: "",
    //   credit_am: "",
    //   delete_row: 0,
    // });

    const addNewRow = () => {
      formData.value["tableData"].push({
        sr_no: "",
        ledger_id: "",
        ac_name: "",
        debit_am: "",
        credit_am: "",
        delete_row: 1,
      });
    };

    const deleteRow = (item) => {
      var idx = formData.value["tableData"].indexOf(item);
      if (idx > -1) {
        formData.value["tableData"].splice(idx, 1);
      }
      calculateDbTotal();
      calculateCdTotal();
    };

    const calculateDbTotal = (row: any = null) => {
      if (row && !/^\d*\.?\d*$/.test(row.debit_am)) {
        row.debit_am = row.debit_am.slice(0, -1);
        return;
      }
      var total = formData.value["tableData"].reduce(function (sum, entry) {
        var io = entry.debit_am == "" ? 0 : entry.debit_am;
        return sum + parseFloat(io.toString());
      }, 0);

      if (!isNaN(total)) {
        formData.value["debit_am_tot"] = parseFloat(
          total.toFixed(2)
        ).toString();
      } else {
        formData.value["debit_am_tot"] = "0.00";
      }
    };

    const calculateCdTotal = (row: any = false) => {
      if (row && !/^\d*\.?\d*$/.test(row.credit_am)) {
        row.credit_am = row.credit_am.slice(0, -1);
        return;
      }

      var total = formData.value["tableData"].reduce(function (sum, entry) {
        var io = entry.credit_am == "" ? 0 : entry.credit_am;
        return sum + parseFloat(io.toString());
      }, 0);

      if (!isNaN(total)) {
        formData.value["credit_amtot"] = parseFloat(
          total.toFixed(2)
        ).toString();
      } else {
        formData.value["credit_amtot"] = "0.00";
      }
    };

    const chkData = () => {
      console.log(formData.value["journal_date"]);
    };
    const checkCreditAmTot = (rule, value, callback) => {
      if (value != formData.value["debit_am_tot"]) {
        callback(
          new Error("Credit total amount should be same as credit total amount")
        );
      }
      if (value === formData.value["debit_am_tot"]) {
        if (formRef.value) {
          formRef.value.validateField("credit_amtot");
        }
      }
      callback();
    };
    const checkDebitAmTot = (rule, value, callback) => {
      console.log("check debit total", formData.value["credit_amtot"], value);
      if (value != formData.value["credit_amtot"]) {
        callback(
          new Error("Debit total amount should be same as credit total amount")
        );
        // return "Debit total amount should be same as credit total amount";
      }
      if (formData.value["credit_amtot"] === value) {
        if (formRef.value) {
          formRef.value.validateField("debit_am_tot");
        }
      }
      callback();
    };
    const rules = ref({
      journal_date_type: [
        {
          required: true,
          message: "Journal Type is required",
          trigger: "change",
        },
      ],
      journal_date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
    });

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (
            formData.value["credit_amtot"] != formData.value["debit_am_tot"]
          ) {
            console.log("validation failed");
            Swal.fire({
              text: "Debit total ammount and Credit total ammount should be same.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            loading.value = false;
            return;
          }
          await updateJournalData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const updateJournalData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      var currDate = moment(data.journal_date).format("YYYY-MM-DD");
      var particulars = data.tableData.map((n, index) => {
        const obj = {
          journal_sr_no: index + 1,
          ledger_id: isNaN(n.ac_name) ? n.ledger_id : n.ac_name,
          transaction_nature_id: n.debit_am != "" ? 2 : 1,
          transaction_amount: n.debit_am != "" ? n.debit_am : n.credit_am,
        };
        return obj;
      });
      console.log("particulars", particulars);
      let validateProductList = particulars.filter((item) => {
        return (
          item.ledger_id != "" &&
          item.transaction_nature_id != "" &&
          item.transaction_amount != ""
        );
      });
      if (validateProductList.length < data.tableData.length) {
        console.log("validation failed");
        Swal.fire({
          text: "Please fill all perticular fields",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        journal_id: props.id,
        journal_date: currDate,
        journal_type_id: data.journal_date_type,
        journal_amount: data.credit_amtot,
        journal_remarks: data.narration,
        user_id: user.user_id,
        particulars: particulars,
      };
      await store
        .dispatch(ActionsFi.CUST_ADD_JOURNAL_ENTRY, db_data)
        .then(({ data }) => {
          debugger;
          if (data) {
            console.log(data);
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Journal entry updated successfully",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addCompanyModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getLedgerData(query: string) {
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };

      await store
        .dispatch(ActionsFi.CUST_FA_SEARCH_LEDGER, values)
        .then(({ data }) => {
          ac_m_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });

      var req_data_JT = { records_per_page: 100, page: 1 };

      await store
        .dispatch(ActionsFi.CUST_GET_JOURNAL_TYPES_LIST, req_data_JT)
        .then(({ data }) => {
          // jn_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getJournalTypes() {
      await store
        .dispatch(ActionsFi.CUST_GET_JOURNAL_TYPE_LIST_DATA)
        .then(({ data }) => {
          jn_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function setJournalData() {
      console.log("journal_id", props.id);
      let req_data = { journal_id: props.id };
      await store
        .dispatch(ActionsFi.CUST_GET_JOURNAL_ENTRY_DATA, req_data)
        .then(({ data }) => {
          if (data) {
            let creditTotal = 0;
            let debitTotal = 0;
            formData.value.journal_date = data?.journal_date;
            formData.value.journal_date_type = data?.journal_type_id;

            data?.particulars.map((item) => {
              formData.value["tableData"].push({
                sr_no: item.journal_sr_no,
                ledger_id: item.ledger_id,
                ac_name: item.ledger_name,
                debit_am:
                  item.transaction_nature_id == 2 ? item.journal_amount : "",
                credit_am:
                  item.transaction_nature_id == 1 ? item.journal_amount : "",
                delete_row: item.journal_sr_no == 1 ? 0 : 1,
              });
              if (item.transaction_nature_id == 1) {
                creditTotal = parseFloat(item.journal_amount) + creditTotal;
              } else {
                debitTotal = parseFloat(item.journal_amount) + debitTotal;
              }
            });
            formData.value.credit_amtot = creditTotal.toString();
            formData.value.debit_am_tot = debitTotal.toString();
            formData.value.narration = data?.journal_remarks;
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Journals Entries View", []);
      await setJournalData();
    });

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
      addNewRow,
      deleteRow,
      calculateDbTotal,
      calculateCdTotal,
      ac_m_data,
      jn_type_data,
      account_type_data,
      account_group_data,
      chkData,
      getLedgerData,
      references,
    };
  },
});
